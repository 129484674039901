import React from "react"

import Layout from "../components/Layout"
import Grid from "../components/Grid"
import css from "./terms.module.scss"

const metadata = {
  title: "Anafore Terms of Service | ReferralCandy",
  noindex: true,
}

const TermsPage = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1>Anafore Terms of Service</h1>
        <p>
          By using the Anafore ReferralCandy web site ("Service"), or any
          services of Anafore Pte Ltd ("Anafore"), you are agreeing to be bound
          by the following terms and conditions ("Terms of Service"). Violation
          of any of the terms below will result in the termination of your
          account. Anafore reserves the right to update and change the Terms of
          Service from time to time. Any new features that augment or enhance
          the current Service, including the release of new tools and resources,
          shall be subject to the Terms of Service. Continued use of the Service
          after any such changes shall constitute your consent to such changes.
          You can review the most current version of the Terms of Service at any
          time on this page.
        </p>

        <h2>Account Terms</h2>
        <ol type="1">
          <li>
            You must provide your organization’s legal full name, a valid email
            address, and any other information requested in order to complete
            the signup process.
          </li>
          <li>
            You must be a human. Accounts registered by "bots" or other
            automated methods are not permitted.
          </li>
          <li>
            You are responsible for maintaining the security of your account and
            password. Anafore cannot and will not be liable for any loss or
            damage from your failure to comply with this security obligation.
          </li>
          <li>
            You are responsible for all Content posted and activity that occurs
            under your account.
          </li>
          <li>
            If you are using a free account you are not permitted to block ads.
          </li>
          <li>
            You may not use the Service for any illegal or unauthorized purpose.
            You must not, in the use of the Service, violate any laws in your
            jurisdiction (including but not limited to copyright laws).
          </li>
        </ol>

        <h2>Cancellation and Termination</h2>
        <ol type="1">
          <li>
            If you cancel the Service before the end of your current paid up
            month, your cancellation will take effect immediately and you will
            not be charged again.
          </li>
          <li>
            All of your Content will be immediately deleted from the Service
            upon cancellation. This information can not be recovered once your
            account is cancelled.
          </li>
          <li>
            Anafore, in its sole discretion, has the right to suspend or
            terminate your account and refuse any and all current or future use
            of the Service, or any other Anafore service, for any reason at any
            time. Such termination of the Service will result in the deletion of
            your Account. Anafore reserves the right to refuse service to anyone
            for any reason at any time.
          </li>
          <li>
            As invoicing is based on services already rendered, Anafore operates
            with a strict no refund policy.
          </li>
        </ol>

        <h2>Modifications to the Service and Prices</h2>
        <ol type="1">
          <li>
            Anafore reserves the right at any time and from time to time to
            modify or discontinue, temporarily or permanently, the Service (or
            any part thereof) with or without notice.
          </li>
          <li>
            Prices of all Services, including but not limited to monthly
            subscription plan fees to the Service, are subject to change upon 30
            days notice from us. Such notice may be provided at any time by
            posting the changes to the Anafore Site (referralcandy.com) or the
            Service itself.
          </li>
          <li>
            Anafore shall not be liable to you or to any third party for any
            modification, price change, suspension or discontinuance of the
            Service.
          </li>
          <li>
            When using PayPal as your payment service, you are expected to
            comply with the{" "}
            <a
              href="https://www.paypal.com/en/webapps/mpp/ua/acceptableuse-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              PayPal Acceptable Use Policy
            </a>
            .
          </li>
        </ol>

        <h2>Copyright and Content Ownership</h2>
        <ol type="1">
          <li>
            All content posted on the Service must comply with U.S. copyright
            law.
          </li>
          <li>
            Anafore does not pre-screen Content, but Anafore has the right (but
            not the obligation) in their sole discretion to remove any Content
            that is available via the Service.
          </li>
        </ol>

        <h2>General Conditions</h2>
        <ol type="1">
          <li>
            Your use of the Service is at your sole risk. The service is
            provided on an "as is" and "as available" basis.
          </li>
          <li>
            You understand that Anafore uses third party vendors and hosting
            partners to provide the necessary hardware, software, networking,
            storage, and related technology required to run the Service.
          </li>
          <li>
            You must not modify, adapt or hack the Service or modify another
            website so as to falsely imply that it is associated with the
            Service, Anafore, or any other Anafore service.
          </li>
          <li>
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Service, use of the Service, or access to the
            Service without the express written permission by Anafore.
          </li>
          <li>
            We may, but have no obligation to, remove Content and Accounts
            containing Content that we determine in our sole discretion are
            unlawful, offensive, threatening, libelous, defamatory,
            pornographic, obscene or otherwise objectionable or violates any
            party’s intellectual property or these Terms of Service.
          </li>
          <li>
            Verbal, physical, written or other abuse (including threats of abuse
            or retribution) of any Anafore customer, employee, member, or
            officer will result in immediate account termination.
          </li>
          <li>
            You understand that the technical processing and transmission of the
            Service, including your Content, may be transfered unencrypted and
            involve (a) transmissions over various networks; and (b) changes to
            conform and adapt to technical requirements of connecting networks
            or devices.
          </li>
          <li>
            You must not upload, post, host, or transmit unsolicited email,
            SMSs, or "spam" messages.
          </li>
          <li>
            You must not transmit any worms or viruses or any code of a
            destructive nature.
          </li>
          <li>
            Anafore does not warrant that (i) the service will meet your
            specific requirements, (ii) the service will be uninterrupted,
            timely, secure, or error-free, (iii) the results that may be
            obtained from the use of the service will be accurate or reliable,
            (iv) the quality of any products, services, information, or other
            material purchased or obtained by you through the service will meet
            your expectations, and (v) any errors in the Service will be
            corrected.
          </li>
          <li>
            You expressly understand and agree that Anafore shall not be liable
            for any direct, indirect, incidental, special, consequential or
            exemplary damages, including but not limited to, damages for loss of
            profits, goodwill, use, data or other intangible losses (even if
            Anafore has been advised of the possibility of such damages),
            resulting from: (i) the use or the inability to use the service;
            (ii) the cost of procurement of substitute goods and services
            resulting from any goods, data, information or services purchased or
            obtained or messages received or transactions entered into through
            or from the service; (iii) unauthorized access to or alteration of
            your transmissions or data; (iv) statements or conduct of any third
            party on the service; (v) or any other matter relating to the
            service.
          </li>
          <li>
            The failure of Anafore to exercise or enforce any right or provision
            of the Terms of Service shall not constitute a waiver of such right
            or provision. The Terms of Service constitutes the entire agreement
            between you and Anafore and govern your use of the Service,
            superceding any prior agreements between you and Anafore (including,
            but not limited to, any prior versions of the Terms of Service).
          </li>
          <li>
            The aggregate liability of Anafore Pte. Ltd. and all Anafore
            entities (including entities directly or indirectly controlled by,
            or under common control with Anafore Pte. Ltd.) for all claims under
            this Terms of Service will not exceed an amount equal to the sum of
            all subscription fees received by Anafore Pte. Ltd. from you.
          </li>
          <li>
            Nothing in this clause applies to exclude or limit Anafore Pte.
            Ltd.’s liability to the extent that a claim is as a result of
            dishonesty, fraud, wilful misconduct or wilful concealment by
            Anafore Pte. Ltd., its agents or advisers.
          </li>
          <li>
            This Terms of Service, and this clause, is governed by the laws of
            the Republic of Singapore. The Parties agree to submit to the
            exclusive jurisdiction of the courts of the Republic of Singapore.
          </li>
          <li>
            Questions about the Terms of Service should be sent to support at
            referralcandy dot com.
          </li>
        </ol>
      </Grid>
    </Layout>
  )
}

export default TermsPage
